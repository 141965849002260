import { ApiClientRequestError } from 'remote/api-client/api-client-error';

export const errorToFriendly = (err: ApiClientRequestError) => {
  switch (err.reason) {
    case 'tenant-not-found':
    case 'namespace-not-found':
      return 'The tenant is no longer available.';
    case 'invite-not-pending':
    case 'invite-not-found':
      return 'Invite is no longer available.';
    case 'db-not-deleted': // This should not happen
      return 'There are active instances that need to be removed before accepting invite.';
    case 'rate-limit-exceeded':
      return 'There was an issue in the system. Please retry in a minute.';
    case 'fail-on-salesforce-cpp-creation':
      return 'There was an issue in Salesforce Registration, no Salesforce CPP was created';
    default:
      return `There was an issue in the system. Please contact our support team with this error id: ${err.errorId}.`;
  }
};
