import React, { SyntheticEvent, useState } from 'react';
import { Database } from 'entities/database';
import { IconButton, Menu, MenuItem } from 'foundation';
import Icon from 'ui/icons';
import { needsMigration } from 'utils/neo4j-versions';

interface Props {
  db: Database;
}

const getOpsManagerSupportUrlForPath = (featurePath: string, opsManagerBaseUrl: string) => {
  const supportUrl = new URL(opsManagerBaseUrl);
  supportUrl.pathname = `${featurePath}/`;
  supportUrl.searchParams.append('supportMode', 'true');
  return supportUrl;
};

const formatSrePortalDomain = (environment: string, neo4jDomain: string) => {
  const env = environment ?? 'production';
  const domain = neo4jDomain ?? 'neo4j.io';
  const subdomain = env === 'production' ? 'sre' : `sre-${env}`;
  return `${subdomain}.${domain}`;
};

export const DatabaseActionsMenu = ({ db }: Props) => {
  const sreDomain = formatSrePortalDomain(process.env.ENVIRONMENT, process.env.NEO4J_DNS_DOMAIN);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <IconButton
        className="tw-ml-2 tw-center"
        onClick={handleOpen}
        color="neutral"
        title="Tenant Databases Actions Menu"
        aria-label="Tenant Databases Actions Menu"
        iconName="EllipsisHorizontalIconOutline"
        data-testid={`tenant-dbs-action-menu-button-${db.DbId}`}
        clean
      />

      <Menu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl}>
        <Menu.Items data-testid="tenant-dbs-actions-menu-items">
          <MenuItem
            title={`SRE Status Page for ${db.DbId}`}
            name={`SRE Status Page for ${db.DbId}`}
            as="a"
            href={`https://${sreDomain}/databases/${db.DbId}/status`}
            target="_blank"
            rel="noopener noreferrer"
            icon={<Icon name="ArrowUpRightIconOutline" className="tw-h-4 tw-w-4 tw-mb-2" />}
          />
          <MenuItem
            title={`SRE Operations Page for ${db.DbId}`}
            name={`SRE Operations Page for ${db.DbId}`}
            as="a"
            href={`https://${sreDomain}/databases/${db.DbId}/operations`}
            target="_blank"
            rel="noopener noreferrer"
            icon={<Icon name="Cog8ToothIconOutline" className="tw-h-4 tw-w-4 tw-mb-2" />}
          />
          {process.env.ENVIRONMENT === 'production' && (
            <MenuItem
              title={`Datastore Entity ${db.DbId}`}
              name={`Datastore Entity ${db.DbId}`}
              as="a"
              href={`https://console.cloud.google.com/datastore/databases/-default-/entities;kind=Database;ns=production/query/kind;queryModel=1%7CWH%7C1%7C4%2Fdbid%7CEQ%7CSTR%7C8%2F${db.DbId}?project=neo4j-cloud`}
              target="_blank"
              rel="noopener noreferrer"
              icon={<Icon name="ArrowUpRightIconOutline" className="tw-h-4 tw-w-4 tw-mb-2" />}
            />
          )}
          {db.OpsManagerUrl !== undefined && (
            <MenuItem
              title={`Advanced metrics for ${db.DbId}`}
              name={`Advanced metrics for ${db.DbId}`}
              as="a"
              href={getOpsManagerSupportUrlForPath('metrics', db.OpsManagerUrl).href}
              target="_blank"
              rel="noopener noreferrer"
              icon={
                <Icon name="ArrowTopRightOnSquareIconOutline" className="tw-h-4 tw-w-4 tw-mb-2" />
              }
            />
          )}
          {db.OpsManagerUrl !== undefined && (
            <MenuItem
              title={`Query Log Analyzer for ${db.DbId}`}
              name={`Query Log Analyzer for ${db.DbId}`}
              as="a"
              href={getOpsManagerSupportUrlForPath('logs', db.OpsManagerUrl).href}
              target="_blank"
              rel="noopener noreferrer"
              icon={
                <Icon name="ArrowTopRightOnSquareIconOutline" className="tw-h-4 tw-w-4 tw-mb-2" />
              }
            />
          )}
          {needsMigration(db.DesiredSettings.Version) && db.OpsManagerUrl !== undefined && (
            <MenuItem
              title={`Migration Readiness Report for ${db.DbId}`}
              name={`Migration Readiness Report for ${db.DbId}`}
              as="a"
              href={
                getOpsManagerSupportUrlForPath('migration-readiness-report', db.OpsManagerUrl).href
              }
              target="_blank"
              rel="noopener noreferrer"
              icon={
                <Icon name="ArrowTopRightOnSquareIconOutline" className="tw-h-4 tw-w-4 tw-mb-2" />
              }
            />
          )}
        </Menu.Items>
      </Menu>
    </>
  );
};
