import React, { useState } from 'react';
import { FormInput, Alert, Button, Form } from 'foundation';
import Actions from 'actions';
import { OAuthClientTable } from './oauth-client-table';
import { OauthClient } from 'types/oauth';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';

export const LookupOAuthClientForm = () => {
  const [clientID, setClientID] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [clients, setClients] = useState<OauthClient[]>([]);

  const handleClientIDChange = ({ target: { value } }) => setClientID(value);

  const handleFindByClientIDSubmit = async () => {
    if (!clientID) return;

    setError(null);

    try {
      const client = await Actions.oauth.findByClientID(clientID.trim().toLowerCase());

      setClients([client]);
      setClientID('');
    } catch (err) {
      if (err instanceof ApiClientRequestError && err.response.status === 404) {
        setError(`No OAuth Client found with ID ${clientID}`);
      } else {
        setError(String(err));
      }
    }
  };

  return (
    <>
      <Form onSubmit={handleFindByClientIDSubmit}>
        <FormInput
          fluid
          value={clientID}
          onChange={handleClientIDChange}
          aria-label="OAuth client id"
          placeholder="OAuth Client ID"
          data-testid="lookup-oauth-client-by-id-input"
        />
        <Button
          onClick={handleFindByClientIDSubmit}
          className="tw-mt-4"
          type="submit"
          color="primary"
          disabled={clientID === ''}
          data-testid="lookup-oauth-client-by-id-button"
        >
          Find by OAuth Client ID
        </Button>
        {error && (
          <Alert type="danger" data-testid="lookup-oauth-client-error-message" className="tw-mt-2">
            {error}
          </Alert>
        )}
      </Form>

      {clients.length > 0 && <OAuthClientTable clients={clients} />}
    </>
  );
};
