import React, { useMemo } from 'react';
import {
  createColumnHelper,
  useDefaultTable,
  DataGrid,
  DataGridComponents,
  Link,
} from 'foundation';
import { FeatureToggleOverride } from 'remote/resources/feature-toggle-overrides';

interface Props {
  data: FeatureToggleOverride[];
}

const helper = createColumnHelper<FeatureToggleOverride>();

export const FeatureTogglesOverviewTable = ({ data }: Props) => {
  const columns = useMemo(
    () => [
      helper.accessor('NamespaceInternalName', {
        header: 'Internal Name',
        cell: cx => {
          const tenantName = cx.getValue();
          return (
            <div className="tw-w-full">
              <code>{tenantName}</code>
            </div>
          );
        },
        size: 400,
        minSize: 100,
      }),
      helper.accessor('NamespaceId', {
        header: 'Tenant ID',
        cell: cx => {
          const tenantId = cx.getValue();
          return (
            <div className="tw-w-full">
              <div className="tw-truncate">
                <Link href={`#admin/tenants/${tenantId}/users`}>
                  <code>{tenantId}</code>
                </Link>
              </div>
            </div>
          );
        },
        size: 400,
        minSize: 100,
      }),
      helper.accessor('NewValue', {
        header: 'Toggle Value',
        minSize: 100,
        cell: cell => <code>{String(cell.getValue())}</code>,
      }),
    ],
    []
  );

  const table = useDefaultTable({ columns, data });

  return (
    <div data-testid="feature-toggles-overview-table">
      <DataGrid
        tableInstance={table}
        styling={{ headerStyle: 'clean' }}
        components={{
          Body: props => (
            <DataGridComponents.Body
              {...props}
              innerProps={{
                style: {
                  borderTop: '1px solid rgb(var(--theme-palette-neutral-border-weak))',
                },
              }}
            />
          ),
        }}
      />
    </div>
  );
};
