import React from 'react';
import ConfirmModal, { ConfirmAction } from 'foundation/confirm-modal';
import { TransferInstanceData } from './transfer-instance-form';

interface Props {
  data: TransferInstanceData;
  open: boolean;
  onClose: () => any;
  onSubmit: () => any;
  loading: boolean;
}

export const TransferInstanceModal = ({ data, open, onClose, onSubmit, loading }: Props) => {
  const targetDescription = data.targetTenantId ? 'tenant' : 'user';
  return (
    <ConfirmModal
      open={open}
      onConfirm={onSubmit}
      onCancel={onClose}
      loading={loading}
      title="Transfer"
      content={
        <p className="tw-my-4">
          This will transfer instance <b>{data.targetDbid}</b> to {targetDescription}{' '}
          <b>{data.targetTenantId || data.targetEmail || data.targetUserId}</b>!{' '}
        </p>
      }
      confirmText="TRANSFER"
      action={ConfirmAction.TRANSFER}
      buttonTextLoading="Transferring"
    />
  );
};
