import React, { useMemo, useState, useEffect, useCallback } from 'react';
import Button from 'foundation/button';

import { useSession } from 'store';
import {
  Database,
  findDatabaseSize,
  isSizeAvailableForVersion,
  isSizeAvailableInRegion,
  Tier,
} from 'entities/database';
import DatabasesResource from 'remote/resources/databases';
import { ResizeDatabaseFormData } from 'application/resize-db/form-data';
import ResizeDatabaseForm, { validate } from './form-resize-database';
import { Alert } from 'foundation/alert';
import Actions from 'actions';
import { Dialog } from 'foundation/dialog';
import { BillingMethod, getCloudProvider } from 'entities/tenant';
import { Form } from 'foundation/form-element';
import logger from 'logger';
import { useNotify } from 'state/notifications';

type Props = {
  open: boolean;
  onClose: () => void;
  database: Database;
};

const ResizeDatabaseModal = ({ open, onClose, database }: Props) => {
  const session = useSession();
  const notify = useNotify();
  const { tenant } = useSession();
  const availableByTierSizes = session.databaseSizes[database.Tier].filter(
    size =>
      !size.deprecated &&
      size.cloud_providers.includes(database.CloudProvider) &&
      isSizeAvailableInRegion(size, database.Region, database.CloudProvider) &&
      isSizeAvailableForVersion(size, database.DesiredSettings.Version, tenant) &&
      !size.is_trial
  );

  const initialSize = findDatabaseSize(availableByTierSizes, database.DesiredSettings.Memory);

  const [data, setData] = useState<ResizeDatabaseFormData>(() => {
    return {
      confirmed: false,
      size: initialSize,
    };
  });
  const [databaseDetail, setDatabaseDetail] = useState<Database | null>(null);

  const fetchDatabase = useCallback(async () => {
    try {
      const dbDetail = await DatabasesResource.get(database.DbId);
      setDatabaseDetail({
        ...dbDetail,
        ResizeThresholdGibibytes: dbDetail.ResizeThresholdGibibytes,
      });
    } catch (err) {
      logger.error(err);
    }
  }, [database]);

  useEffect(() => {
    fetchDatabase();
  }, [database]);
  const [updateRequestInProgress, setUpdateRequestInProgress] = useState(false);

  const handleChange = (newData: ResizeDatabaseFormData) => setData(newData);

  const updateDatabase = async () => {
    setUpdateRequestInProgress(true);

    try {
      await Actions.databases.updateDatabase(database.DbId, {
        Memory: data.size.memory,
        Storage: data.size.storage,
        Cpu: data.size.cpu,
      });
      setUpdateRequestInProgress(false);
      onClose();
    } catch (e) {
      setUpdateRequestInProgress(false);
      logger.error(e);
      notify.error('Failed to resize instance');
    }
  };

  const validationOk = useMemo(() => validate(data, initialSize), [data, initialSize]);
  const isPrepaidTenant = session.tenant.billingMethod === BillingMethod.PREPAID;
  const isPrepaidOnlyOptionSelected = useMemo(() => !!data?.size?.prepaid_only, [data]);
  const disableResize = useMemo(() => {
    return !validationOk || (isPrepaidOnlyOptionSelected && !isPrepaidTenant);
  }, [validationOk, isPrepaidOnlyOptionSelected, isPrepaidTenant]);

  const resizeMessage = [Tier.GDS, Tier.AURA_DSE].includes(database.Tier) ? (
    <p>Increasing your instance will schedule a small amount of unavailability.</p>
  ) : (
    'You can adjust the capacity of your instance without downtime.'
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      size="unset"
      modalProps={{
        'data-testid': 'resize-database-modal',
        className: 'tw-max-w-[850px] !tw-overflow-y-auto',
      }}
    >
      <Dialog.Header>Resize instance</Dialog.Header>
      <Dialog.Content>
        <Alert description={resizeMessage} />
        <Form onSubmit={updateDatabase} data-testid="resize-db-form">
          <ResizeDatabaseForm
            data={data}
            onChange={handleChange}
            tier={database.Tier}
            sizes={availableByTierSizes}
            cloudProvider={getCloudProvider(session.providerConfigs)}
            region={database.Region}
            version={database.DesiredSettings.Version}
            initialSize={initialSize}
            resizeThresdhold={databaseDetail?.ResizeThresholdGibibytes}
          />
        </Form>
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          onClick={onClose}
          data-testid="clone-cancel-button"
          fill="outlined"
          color="neutral"
          disabled={updateRequestInProgress}
        >
          Cancel
        </Button>
        <Button
          disabled={disableResize}
          onClick={updateDatabase}
          loading={updateRequestInProgress}
          data-testid="update-database"
        >
          Resize
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export default ResizeDatabaseModal;
