import { EncryptionKey } from 'types/encryption-keys';
import { CDCEnrichmentMode } from 'application/edit-cdc-enrichment-mode/form-data';
import { ActionStatus } from 'types/action-status';

export const DATABASE_NAME_CHARACTER_LIMIT = 30;

export enum DatabaseStatus {
  CREATING = 'creating',
  RUNNING = 'running',
  LOADING_DATA = 'loading',
  LOADING_FAILED = 'loading failed',
  UPDATING = 'updating',
  RESTORING = 'restoring',
  DESTROYING = 'destroying',
  DESTROYED = 'destroyed',
  PAUSING = 'pausing',
  PAUSED = 'paused',
  RESUMING = 'resuming',
  OVERWRITING = 'overwriting',
  // These are currently client-side only statuses
  // They correspond to when a DB is Paus-ing/ed within
  // a suspended tenant.
  SUSPENDING = 'suspending',
  SUSPENDED = 'suspended',
  //Derived from the Encryption Key Status
  ENCRYPTION_KEY_DELETED = 'encryption key deleted',
  ENCRYPTION_KEY_ERROR = 'encryption key error',

  // In the odd case EncryptionKeyRef is invalid
  ENCRYPTION_KEY_NOT_FOUND = 'encryption key not found',
}

export enum Tier {
  FREE = 'free',
  GDS = 'gds',
  PROFESSIONAL = 'professional',
  ENTERPRISE = 'enterprise',
  AURA_DSE = 'dsenterprise',
  MTE = 'mte',
}
// Alias used for Tier.MTE in public URLs
export const BUSINESS_CRITICAL_TIER = 'business-critical';

export enum CloudProvider {
  GCP = 'gcp',
  AWS = 'aws',
  AZURE = 'azure',
}

export enum MonitoringStatus {
  OK = 'ok',
  WARNING = 'warning',
  CRITICAL = 'critical',
}

export interface Database {
  Tier: Tier;
  TierDisplayName: string;
  DatabaseStatus: DatabaseStatus;
  MonitoringStatus: MonitoringStatus;
  CloudProvider: CloudProvider;
  DbId: string;
  Name: string;
  IsBeingCloned: boolean;
  ConnectionUri: string;
  BoltUrl: string;
  Region: string;
  BrowserUrl: string;
  BloomUrl: string;
  WorkspaceUrl?: string;
  OpsManagerUrl?: string;
  DataImporterUrl?: string;
  loading?: boolean;
  AppliedSettings: {
    Memory?: string;
    Storage?: string;
    Cpu?: number;
    SecondariesCount?: number;
    CDCEnrichmentMode?: CDCEnrichmentMode;
  };
  DesiredSettings: {
    Memory: string;
    Storage?: string;
    Cpu?: number;
    Version: string;
    SecondariesCount?: number;
    CDCEnrichmentMode?: CDCEnrichmentMode;
  };
  Counts?: { CurrentNodes: number; CurrentRelationships: number };
  Limits?: { MaxNodes: number; MaxRelationships: number };
  PrivateBoltUrl?: string;
  PublicAccessEnabled?: boolean;
  EncryptionKey?: EncryptionKey;
  NamespaceId: string;
  Capabilities: DatabaseCapabilities;
  AvailableActions: AvailableDatabaseActions;
  ProTrialEndTime?: string; // ISO timestamp
  ResizeThresholdGibibytes?: number;
}

export interface DatabaseSize {
  size_id: string;
  memory: string;
  cpu: string;
  storage: string;
  cost_per_hour: string;
  cloud_providers: string[];
  unavailable_regions: { cloud_provider: string; region: string }[];
  deprecated: boolean;
  minimum_required_version?: string;
  is_trial: boolean;
  prepaid_only: boolean;
}

export type DatabaseSizeMap = Record<string, DatabaseSize[]>;

export type Region = {
  name: string;
  /** User-friendly name */
  friendly: string;
};

export interface CreateDatabaseResponse {
  DbId: string;
  Password: string;
  BoltUrl: string;
  Username: string;
}

export interface SignedUrlObject {
  CloudStorageObject: any;
  SignedUrl: string;
}

export type DatabaseCapability = {
  enabled: boolean;
  reason?: string;
  message?: string;
};

export type DatabaseCapabilities = {
  pause: DatabaseCapability;
  cdc_enrichment_mode: DatabaseCapability;
};

export type AvailableDatabaseActions = {
  clone: ActionStatus;
  delete: ActionStatus;
  import: ActionStatus;
  overwrite: ActionStatus;
  pause: ActionStatus;
  resize: ActionStatus;
  restore: ActionStatus;
  resume: ActionStatus;
  take_snapshot: ActionStatus;
  secondaries: ActionStatus;
  cdc_enrichment_mode: ActionStatus;
};

export type SpdProperties = {
  numOfShards: number;
  numOfGraphSecondaries: number;
  numOfShardSecondaries: number;
  seedType: string;
  seedUri?: string;
  graphSeedUri?: string;
  propertyShardsSeedUris?: string[];
};
