import React, { ChangeEvent } from 'react';
import { FormInput, FormSelect } from 'foundation/form-element';
import * as yup from 'yup';
import { validateYup, Validation } from 'utils/validation';
import {
  getTenantRoleOptions,
  getOrganizationRoleOptions,
  isTenantRole,
  isOrganizationRole,
  RoleName,
} from 'types/user';
import { Invite } from 'types/invite';
import { Tenant } from 'entities/tenant';

export interface InviteFormData {
  inviteId: string;
  tenantRole: RoleName;
  organizationRole: RoleName;
}

interface Props {
  data: InviteFormData;
  onChange: (data: InviteFormData) => any;
  validation: Validation<InviteFormData>;
  disabled?: boolean;
  edit?: boolean;
  tenant: Tenant;
}

const generateSchema = (enforceEmail: boolean) => {
  let idField = yup.string();

  if (enforceEmail) {
    idField = idField
      .email('Please enter a valid email address')
      .required('Please enter an email address');
  } else {
    idField = idField.required('Please enter a valid invite id');
  }

  return yup.object().shape({
    inviteId: idField,
  });
};

export const validate = (
  data: InviteFormData,
  enforceEmail: boolean = true // When inviting, we invite by email, but existing invites can use subject id as the identifier. GCP invites work this way
): Validation<InviteFormData> => {
  const schema = generateSchema(enforceEmail);
  return validateYup(schema, data);
};

export const defaults = (tenant: Tenant, invite?: Invite): InviteFormData => {
  if (invite) {
    const tenantRole = invite.Roles.find(role => isTenantRole(role)) ?? RoleName.TENANT_MEMBER;
    const organizationRole =
      tenant.organizationId &&
      (invite.Roles.find(role => isOrganizationRole(role)) ?? RoleName.ORGANIZATION_MEMBER);
    return {
      inviteId: invite.InviteId,
      tenantRole: tenantRole,
      organizationRole: organizationRole,
    };
  }
  return {
    inviteId: '',
    tenantRole: RoleName.TENANT_MEMBER,
    organizationRole: tenant.organizationId && RoleName.ORGANIZATION_MEMBER,
  };
};

const InviteFormFields = ({ data, onChange, validation, disabled, edit, tenant }: Props) => {
  const handleInviteIdChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    onChange({ ...data, inviteId: value.toLowerCase().trim() });

  const handleTenantRoleChange = selected => {
    onChange({ ...data, tenantRole: selected.value });
  };

  const handleOrganizationRoleChange = selected => {
    onChange({ ...data, organizationRole: selected.value });
  };

  const roleOptions = getTenantRoleOptions(tenant);
  const orgRoleOptions = getOrganizationRoleOptions();

  return (
    <>
      <FormInput
        fluid
        data-testid="invite-id-input"
        value={data.inviteId}
        onChange={handleInviteIdChange}
        // We only create invites by Email in the UI. But we might be editing some with subject id
        label={edit ? 'Invite Id' : 'Email'}
        placeholder="Email"
        errorText={validation?.inviteId?.message}
        disabled={disabled || edit}
      />
      <FormSelect<RoleName>
        label="Tenant Role"
        data-testid="invite-roles-select"
        onChange={handleTenantRoleChange}
        disabled={disabled}
        errorText={validation?.tenantRole?.message}
        value={data.tenantRole}
        options={roleOptions}
      />
      {tenant.organizationId && (
        <FormSelect<RoleName>
          label="Organization Role"
          data-testid="invite-org-roles-select"
          onChange={handleOrganizationRoleChange}
          disabled={disabled}
          errorText={validation?.organizationRole?.message}
          value={data.organizationRole}
          options={orgRoleOptions}
        />
      )}
    </>
  );
};

export default InviteFormFields;
