import React, { useState } from 'react';
import { CloudProvider, Region, Tier } from 'entities/database';
import { getProductFromTier, Tenant } from 'entities/tenant';
import { useDefaultErrorHandler } from 'remote/error-handler';
import { validateYup, Validation } from 'utils/validation';
import { useNotify } from 'state/notifications';
import * as yup from 'yup';
import Icon from 'ui/icons';
import { TextLink, Tip } from 'foundation';
import { productFriendlyName } from 'types/product';
import { Alert, Button, Dialog, FormInput, FormSelect } from 'components/foundation';
import EncryptionKeyResource from 'remote/resources/encryption-keys';
import { EncryptionKey } from 'types/encryption-keys';
import { getRegionsForTier, getTiersForAllCsps } from 'utils/tiers-and-regions';

interface EncryptionKeyFormData {
  tier?: Tier;
  region?: string;
  name: string;
  keyId: string;
}

const schema = yup.object({
  tier: yup
    .string()
    .oneOf(Object.values(Tier))
    .required()
    .label('Product'),
  region: yup
    .string()
    .required()
    .label('Region'),
  name: yup
    .string()
    .required()
    .label('Name'),
  keyId: yup
    .string()
    .required()
    .label('KeyId'),
});

const validate = (data: EncryptionKeyFormData): Validation<EncryptionKeyFormData> => {
  return validateYup(schema, data);
};

const keyDefaults: EncryptionKeyFormData = {
  tier: undefined,
  region: undefined,
  name: '',
  keyId: '',
};

const CmekForm = ({
  tenant,
  step,
  cloudProviders,
  updateStep,
  onClose,
  onSuccess,
  updateEncryptionKey,
}: {
  tenant: Tenant;
  step: number;
  cloudProviders: string[];
  updateStep: (step: number) => void;
  onClose: () => void;
  onSuccess: (encryptionKey: EncryptionKey) => void;
  updateEncryptionKey: (encryptionKey: EncryptionKey) => void;
}) => {
  const [data, setData] = useState<EncryptionKeyFormData>({ ...keyDefaults });
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const defaultErrorHandler = useDefaultErrorHandler();
  const [validation, setValidation] = useState<Validation<EncryptionKeyFormData>>({});
  const [loading, setLoading] = useState(false);
  const [createValidationError, setCreateValidationError] = useState<string>('');
  const notify = useNotify();
  const providerConfigs = tenant.providerConfigs;

  if (cloudProviders.length > 1) {
    notify.error(
      'Unable to configure encryption keys for this tenant. Tenant is not isolated to a single cloud provider'
    );
    return;
  }

  const handleSubmitAndNextStep = async () => {
    setCreateValidationError('');

    const errors = validate(data);
    if (errors) {
      setValidation(errors);
      return;
    }
    setValidation({});
    setLoading(true);

    try {
      const newEncryptionKey = await EncryptionKeyResource.create(
        tenant.id,
        data.tier,
        data.region,
        data.name,
        data.keyId,
        cloudProviders[0]
      );
      setLoading(false);
      setData({ ...keyDefaults });
      onSuccess(newEncryptionKey);
      updateStep(step + 1);
      updateEncryptionKey(newEncryptionKey);
    } catch (err) {
      setLoading(false);
      if (err.response.status === 422) {
        const errorText = `Error: Unable to add key. ${err.responseMessage ||
          'Ensure that the key name is unique.'}`;
        setCreateValidationError(errorText);
      } else {
        defaultErrorHandler(err);
      }
    }
  };

  const handleTierChange = option =>
    setData(prev => ({ ...prev, tier: option.value, region: undefined }));

  const handleRegionChange = option => setData(prev => ({ ...prev, region: option.value }));

  const handleNameChange = e => {
    const newValue = e.target.value;
    setData(prev => ({ ...prev, name: newValue }));
  };

  const handleKeyIdChange = e => {
    const newValue = e.target.value;
    setData(prev => ({ ...prev, keyId: newValue }));
  };

  const tiers = getTiersForAllCsps(providerConfigs);
  let regions: Region[] | null = null;
  let regionOptions = [];

  if (data.tier) {
    regions = getRegionsForTier(providerConfigs, data.tier);
    regionOptions = regions.map(r => ({
      key: r.name,
      label: r.friendly,
      value: r.name,
    }));
  }

  const getTierOptions = () => {
    let productTiers = tiers;
    if (tiers.length > 1) {
      // Ensure there are no duplicate product options, in case of multiple tiers.
      // What we lose in observability, we gain in predictability...
      productTiers = tiers.filter(tier => {
        return [Tier.AURA_DSE, Tier.ENTERPRISE].includes(tier);
      });
    }

    return productTiers.map(tier => {
      const product = getProductFromTier(tier);
      return {
        key: tier,
        value: tier,
        label: productFriendlyName(product),
      };
    });
  };

  const dataIsValid = data.tier && data.region && data.keyId && data.name;
  return (
    <Dialog open onClose={onClose} modalProps={{ 'data-testid': 'new-encryption-key-dialog' }}>
      <>
        <Dialog.Header>{'New Customer Managed Key'}</Dialog.Header>
        <Dialog.Content className="tw-flex tw-flex-col tw-gap-4">
          <Alert type="warning">
            CAUTION: Neo4j Aura will not be able to administer instances where keys are deleted or
            permissions revoked.
          </Alert>
          <FormSelect
            label="Product"
            options={getTierOptions()}
            value={data.tier}
            onChange={handleTierChange}
            errorText={validation.tier?.message}
            data-testid="cmek-select-product"
          />
          {/*Select Region*/}
          <FormSelect
            label="Region"
            options={regionOptions}
            value={data.region ?? ''}
            onChange={handleRegionChange}
            errorText={validation.region?.message}
            data-testid="cmek-select-region"
          />
          {/*Enter name*/}
          <FormInput
            fluid
            aria-label="Encryption Key Name"
            label="Encryption Key Name"
            placeholder="Enter a name..."
            value={data.name}
            onChange={handleNameChange}
            errorText={validation.name?.message}
            data-testid="cmek-name-input"
          />
          {/*Key ID*/}
          <div>
            <FormInput
              fluid
              aria-label={
                cloudProviders[0] === CloudProvider.AWS
                  ? 'Encryption Key ARN'
                  : cloudProviders[0] === CloudProvider.AZURE
                  ? 'Key Identifier'
                  : 'Encryption Key Resource Name'
              }
              label={
                cloudProviders[0] === CloudProvider.AWS ? (
                  'Encryption Key ARN'
                ) : cloudProviders[0] === CloudProvider.AZURE ? (
                  <div className="tw-flex">
                    <div>Key Identifier</div>
                    <div onMouseLeave={() => setIsTooltipOpen(false)}>
                      <Tip allowedPlacements={['top']} isOpen={isTooltipOpen}>
                        <Tip.Trigger onMouseEnter={() => setIsTooltipOpen(true)}>
                          <span className="tw-inline">
                            <Icon
                              name="QuestionMarkCircleIconOutline"
                              type="solid"
                              className="tw-inline-block tw-h-5 tw-w-5 tw-ml-1 tw-mb-1"
                              aria-label="Help Azure Key Identifier"
                            />
                          </span>
                        </Tip.Trigger>
                        <Tip.Content
                          className="tw-max-w-sm"
                          isPortaled={false}
                          onMouseLeave={() => setIsTooltipOpen(false)}
                        >
                          <Tip.Body>
                            <p className="n-body-small">
                              Follow the documentation{' '}
                              <TextLink
                                href="https://neo4j.com/docs/aura/platform/security/encryption/#_azure_keys"
                                className="tw-text-palette-neutral-text-inverse hover:tw-text-palette-neutral-text-inverse"
                              >
                                Azure keys
                              </TextLink>{' '}
                              for where to find your key identifier
                            </p>
                          </Tip.Body>
                        </Tip.Content>
                      </Tip>
                    </div>
                  </div>
                ) : (
                  'Encryption Key Resource Name'
                )
              }
              placeholder={
                cloudProviders[0] === CloudProvider.AWS
                  ? 'Enter the Encryption Key ARN...'
                  : cloudProviders[0] === CloudProvider.AZURE
                  ? 'Enter the Encryption Key Identifier...'
                  : 'Enter the Encryption Key Resource Name...'
              }
              value={data.keyId}
              onChange={handleKeyIdChange}
              errorText={validation.keyId?.message}
              data-testid="cmek-key-id-input"
            />
            {cloudProviders[0] === CloudProvider.GCP && (
              <p className="tw-font-light tw-text-xs tw-mt-2">
                <span>Example format:</span>
                <br />
                <code className="tw-font-light tw-text-xs">
                  {
                    'projects/<project-name>/locations/<location-name>/keyRings/<keyring-name>/cryptoKeys/<key-name>'
                  }
                </code>
              </p>
            )}
          </div>
          {createValidationError && (
            <Alert className="tw-mt-4" description={createValidationError} type="danger" />
          )}
        </Dialog.Content>
        <Dialog.Actions className="tw-justify-between">
          <div className="tw-flex tw-gap-x-4">
            <Button fill="outlined" color="neutral" onClick={onClose}>
              Cancel
            </Button>
          </div>
          <Button
            disabled={!dataIsValid}
            color="primary"
            onClick={handleSubmitAndNextStep}
            data-testid="submit-cmek-button"
            loading={loading}
          >
            Add Key
          </Button>
        </Dialog.Actions>
      </>
    </Dialog>
  );
};

export { CmekForm };
