import { useQuery, UseQueryResult } from 'react-query';
import ApiClient from 'remote/api-client';
import { TrialCustomerInfo, Organization } from 'types/organization';
import { useSession } from 'store';
import { OrganizationMember, RoleName } from 'types/user';
import { BillingAccount } from 'types/billing-account';

export type CreateOrganizationEntity = Pick<
  Organization,
  'name' | 'displayName' | 'planType' | 'profile'
>;
export type UpdateOrganizationEntity = Partial<
  Pick<
    Organization,
    'displayName' | 'passwordLoginEnabled' | 'googleLoginEnabled' | 'copilotEnabled'
  >
>;

export interface SearchOrganizationResponse {
  organizations: Organization[];
  hasHitLimit: Boolean;
}

const transformBillingAccountResponse = (data: Record<string, any>): BillingAccount => {
  return {
    id: data.billing_account_id,
    billingModel: data.billing_model,
    billingProcessor: data.billing_processor,
    organizationId: data.organization_id,
    paymentMethod: data.payment_method,
    pricingPlan: data.pricing_plan,
    projectIds: data.project_ids,
    reason: data.reason,
    status: data.status,
  };
};

const transformOrganizationResponse = (data: Record<string, any>): Organization => {
  return {
    id: data.id,
    ssoOrgId: data.sso_org_id,
    displayName: data.display_name,
    planType: data.plan_type,
    profile: data.profile,
    name: data.name,
    passwordLoginEnabled: data.password_login_enabled,
    googleLoginEnabled: data.google_login_enabled,
    copilotEnabled: data.copilot_enabled,
    capabilities: data.capabilities,
    billingAccounts: (data.billing_accounts ?? []).map(transformBillingAccountResponse),
  };
};

const transformTrialCustomerInfoResponse = (data: Record<string, any>): TrialCustomerInfo => {
  return {
    firstName: data.FirstName,
    lastName: data.LastName,
    jobRole: data.JobRole,
    jobRoleOther: data.JobRolebRoleOther,
    companyName: data.CompanyName,
    primaryUseCase: data.PrimaryUseCase,
    primaryUseCaseOther: data.PrimaryUseOther,
    country: data.Country,
    subdivision: data.Subdivision,
  };
};

const list = (): Promise<Organization[]> => {
  return ApiClient.get('/organizations')
    .ignoreAllErrors()
    .promise()
    .then(orgs => orgs.map(transformOrganizationResponse));
};

const listByUser = (user_id: string): Promise<Organization[]> => {
  return ApiClient.get(`users/${user_id}/organizations`)
    .ignoreAllErrors()
    .promise()
    .then(orgs => orgs.map(transformOrganizationResponse));
};

const get = (id: string): Promise<Organization> => {
  return ApiClient.get(`/organizations/${id}`)
    .ignoreAllErrors()
    .promise()
    .then(transformOrganizationResponse);
};

const create = (entity: CreateOrganizationEntity): Promise<Organization> => {
  return ApiClient.post('/organizations')
    .issue({
      name: entity.name,
      display_name: entity.displayName,
      plan_type: entity.planType,
      profile: entity.profile,
    })
    .ignoreAllErrors()
    .promise()
    .then(transformOrganizationResponse);
};

const update = (id: string, entity: UpdateOrganizationEntity): Promise<Organization> => {
  return ApiClient.patch(`/organizations/${id}`)
    .issue({
      display_name: entity.displayName,
      password_login_enabled: entity.passwordLoginEnabled,
      google_login_enabled: entity.googleLoginEnabled,
      copilot_enabled: entity.copilotEnabled,
    })
    .ignoreAllErrors()
    .promise()
    .then(transformOrganizationResponse);
};

const search = async (namePrefix: string): Promise<SearchOrganizationResponse> =>
  ApiClient.get(`/organizations?name=${namePrefix}&paginate=true`)
    .promise()
    .then(res => {
      return {
        organizations: res.Data.map(transformOrganizationResponse),
        hasHitLimit: res.HasHitLimit,
      };
    });

const linkSsoConfig = (organizationId: string, ssoConfigId: string): Promise<Organization> => {
  return ApiClient.post(`/organizations/${organizationId}/sso-configs/${ssoConfigId}/link`)
    .ignoreAllErrors()
    .promise();
};

const unlinkSsoConfig = (organizationId: string, ssoConfigId: string): Promise<Organization> => {
  return ApiClient.delete(`/organizations/${organizationId}/sso-configs/${ssoConfigId}/link`)
    .ignoreAllErrors()
    .promise();
};

export const transferTenant = (organizationId: string, tenantId: string): Promise<Organization> => {
  return ApiClient.post(`/tenants/${tenantId}/transfer`)
    .issue({
      target_organization_id: organizationId,
    })
    .ignoreAllErrors()
    .promise();
};

const getSsoId = (id: string): Promise<{ id: string; ssoId: string }> => {
  return ApiClient.get(`/organizations/${id}/sso-id`)
    .ignoreAllErrors()
    .promise()
    .then(r => ({ id: r.id, ssoId: r.sso_id }));
};

export const useOrganizationQuery = (organizationId: string): UseQueryResult<Organization> => {
  const query = async () => {
    const organization = await get(organizationId);
    return organization;
  };

  return useQuery(`organization-${organizationId}`, query, {
    enabled: !!organizationId,
    refetchInterval: 10000,
  });
};

export const useOrganizationsListQuery = (): UseQueryResult<Organization[]> => {
  const query = async () => {
    const databases = await list();
    return databases;
  };

  return useQuery('organizations', query, {
    refetchInterval: 5000,
  });
};

export const useUserOrganizationsListQuery = (): UseQueryResult<Organization[]> => {
  const session = useSession();

  const query = async () => {
    const organizations = await listByUser(session.userId);
    return organizations;
  };

  return useQuery(`${session.userId}-organizations`, query, {
    refetchInterval: 20000,
  });
};

export const getMembers = (id): Promise<OrganizationMember[]> => {
  return ApiClient.get(`/organizations/${id}/members`)
    .ignoreAllErrors()
    .promise();
};

export const patchMemberRoles = (
  id: string,
  targetUserId: string,
  roles: RoleName[]
): Promise<OrganizationMember[]> => {
  return ApiClient.patch(`/organizations/${id}/members/${targetUserId}`)
    .issue({ roles })
    .ignoreAllErrors()
    .promise();
};

export const useOrganizationMembersQuery = (orgId): UseQueryResult<OrganizationMember[]> => {
  const query = async () => {
    const tenants = await getMembers(orgId);
    return tenants;
  };

  return useQuery(`organization-members-${orgId}`, query, {
    enabled: !!orgId,
    refetchInterval: 10000,
  });
};

export const extendProTrial = (id: string): Promise<Organization> => {
  return ApiClient.post(`/organizations/${id}/trial/extend`)
    .ignoreAllErrors()
    .promise()
    .then(transformOrganizationResponse);
};

export const addTrialCustomerInfo = (
  orgId: string,
  payload: TrialCustomerInfo
): Promise<TrialCustomerInfo> => {
  return ApiClient.post(`/organizations/${orgId}/trial/customer-info`)
    .issue({
      FirstName: payload.firstName,
      LastName: payload.lastName,
      JobRole: payload.jobRole,
      JobRoleOther: payload.jobRoleOther || null,
      CompanyName: payload.companyName,
      PrimaryUseCase: payload.primaryUseCase || null,
      PrimaryUseCaseOther: payload.primaryUseCaseOther || null,
      PrimaryBusinessLocationCountry: payload.country,
      PrimaryBusinessLocationSubdivision: payload.subdivision || null,
    })
    .promise()
    .then(transformTrialCustomerInfoResponse);
};

export const checkTrialCustomerInfoCanBeSubmitted = async (orgId: string): Promise<boolean> => {
  let canBeSubmitted = false;
  await ApiClient.get(`/organizations/${orgId}/trial/customer-info`)
    .ignore(403)
    .promise()
    .catch(e => {
      canBeSubmitted = e.response?.status === 404;
    });

  return canBeSubmitted;
};

export default {
  create,
  get,
  list,
  update,
  search,
  linkSsoConfig,
  unlinkSsoConfig,
  transferTenant,
  getMemberRoles: getMembers,
  useOrganizationMembersQuery,
  patchMemberRoles,
  extendProTrial,
  getSsoId,
};
