import React, { useState } from 'react';
import { FormInput, Alert, Button, Form } from 'foundation';
import NamespacesResource from 'remote/resources/namespaces';
import { TenantsTable } from './tenants-table';
import { InternalTenant } from 'entities/tenant';
import { useNotify } from 'state/notifications';

export const SearchTenantsForm = ({ ...rest }) => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [tenants, setTeanants] = useState<InternalTenant[]>([]);
  const notify = useNotify();

  const handleQueryChange = ({ target: { value } }) => setQuery(value);

  const handleSearchSubmit = async () => {
    if (!query) return;
    setLoading(true);
    setTeanants([]);
    setError(null);

    try {
      const searchResult = await NamespacesResource.search(query.trim());
      setTeanants(searchResult.tenants);
      if (searchResult.hasHitLimit) {
        notify.warning(
          'Only the first 100 search results are displayed. Update the search string to refine the search results.'
        );
      }
    } catch (err) {
      setError(String(err));
    }
    setLoading(false);
  };

  return (
    <div {...rest}>
      <p className="tw-mb-2">This form enables you to search for tenant by internal name.</p>
      <p className="tw-mb-2">
        An internal name is a human understandable way to identify a tenant, such as{' '}
        <code>&apos;acmecorp&apos;</code>.
      </p>
      <p className="tw-mb-2">
        They are a more meaningful and memorable alternative to UUIDs, such as{' '}
        <code>&apos;4a03fc7d-5749-4a7f-8bbf-0d71bee08d5e&apos;</code>.
      </p>
      <p className="tw-mb-2">You can search for an exact match or by prefix.</p>
      <p className="tw-mb-2">
        For example, if you are looking for <code>&apos;acmecorp-2&apos;</code>:
      </p>
      <ul className="tw-list-disc tw-ml-8 tw-mb-8">
        <li>&quot;acme&quot; ✅</li>
        <li>&quot;acmecorp-2&quot; ✅</li>
        <li>&quot;corp&quot; ❌</li>
      </ul>

      <Form onSubmit={handleSearchSubmit}>
        <FormInput
          fluid
          value={query}
          onChange={handleQueryChange}
          aria-label="Internal tenant name"
          data-testid="lookup-tenants-by-query-input"
          name="internal_name_prefix"
          autoComplete="internal_name_prefix"
          helpText="Search for tenant by Internal Name prefix"
        />
        <Button
          className="tw-mt-8"
          type="submit"
          color="primary"
          loading={loading}
          disabled={query === '' || loading}
          data-testid="lookup-tenants-by-query-button"
        >
          Search
        </Button>

        {error && (
          <Alert type="danger" data-testid="lookup-tenants-error-message" className="tw-mt-8">
            {error}
          </Alert>
        )}
      </Form>

      {tenants.length > 0 && (
        <TenantsTable tenants={tenants} data-testid="search-tenants-result-table" />
      )}
    </div>
  );
};
