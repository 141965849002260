import React, { useEffect, useState } from 'react';
import { Validation } from 'utils/validation';
import Actions from 'actions';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';
import InviteFormFields, { validate, defaults, InviteFormData } from './invite-form-fields';
import './invite.css';
import { Button, Link, Alert, Form } from 'foundation';
import * as yup from 'yup';
import { Tenant } from 'entities/tenant';

interface Props {
  tenant: Tenant;
}

const getErrorContent = (
  err: ApiClientRequestError,
  email: string,
  tenantId: string
): JSX.Element => {
  switch (err.reason) {
    case 'duplicate-invite':
      return (
        <>
          An invite already exists for {email}
          {err.extra && err.extra.Namespace ? ` in tenant ${err.extra.Namespace}` : ''}.
        </>
      );
    case 'tenant-not-found':
    case 'namespace-not-found':
    case 'project-not-found':
      return (
        <>
          <div>
            The tenant <code>{tenantId}</code> does not exist.
          </div>
          <div className="tw-mt-4">
            You can try searching for it <a href="#admin/projects">here</a>.
          </div>
          <div className="tw-mt-4">
            Or you may create it by going to the{' '}
            <Link href="#admin/projects/create" target="_blank" rel="noopener noreferrer">
              Tenant Create form
            </Link>
            .
          </div>
        </>
      );
    case 'unauthorized':
      return (
        <>
          You do not have permission to invite users to tenant <code>{tenantId}</code>.
        </>
      );
    case 'user-already-in-tenant':
    case 'user-already-in-namespace':
      return (
        <>
          {email} is already a member of <code>{tenantId}</code>.
        </>
      );
    case 'db-not-deleted':
      return (
        <>
          Can&apos;t invite {email} to {tenantId}. The users has running instances in their
          self-serve tenant.
        </>
      );
    default:
      return (
        <>
          Something unexpected went wrong. Please try again or contact the Aura Console team for
          assistance.
        </>
      );
  }
};

const isUuid = val =>
  yup
    .string()
    .uuid()
    .isValidSync(val);

const InviteForm = ({ tenant }: Props) => {
  const [data, setData] = useState(defaults(tenant));
  const [validation, setValidation] = useState<Validation<InviteFormData>>(null);
  const [hasFailedValidation, setHasFailedValidation] = useState(false);
  const [submittedEmail, setSubmittedEmail] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const reset = () => {
    setData(defaults(tenant));
    resetValidation();
  };

  const resetValidation = () => {
    if (submittedEmail) setSubmittedEmail(null);
    if (validation) setValidation(null);
    if (error) setError(null);
    if (hasFailedValidation) setHasFailedValidation(false);
  };

  useEffect(() => {
    reset();
  }, [tenant.id]);

  const handleDataChange = newData => {
    setData(newData);

    if (hasFailedValidation) {
      setValidation(validate(newData));
    }
  };

  const handleSubmit = async () => {
    const newValidation = validate(data);
    if (newValidation) {
      setValidation(newValidation);
      setHasFailedValidation(true);
      setError(null);
      setSubmittedEmail(null);
      return;
    }
    setLoading(true);
    resetValidation();

    let roles;
    if (tenant.organizationId) {
      roles = [data.tenantRole, data.organizationRole];
    } else {
      roles = [data.tenantRole];
    }
    try {
      if (!isUuid(tenant.id)) {
        setError(`Invalid tenant ID: '${tenant.id}', expected a UUID value.`);
        return;
      }
      await Actions.invites.inviteUserToTenant(tenant.id, data.inviteId, roles);
      reset();
      setSubmittedEmail(data.inviteId);
    } catch (err) {
      setError(getErrorContent(err, data.inviteId, tenant.id));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit} className="tw-flex tw-flex-col tw-gap-2">
      <InviteFormFields
        data={data}
        onChange={handleDataChange}
        validation={validation}
        tenant={tenant}
      />
      <Button data-testid="invite-button" color="primary" loading={loading} type="submit">
        Invite
      </Button>
      {submittedEmail && (
        <Alert type="success" data-testid="invite-success-message" className="tw-mt-2">
          {submittedEmail} was invited to {tenant.id}.
        </Alert>
      )}
      {error && (
        <Alert type="danger" data-testid="invite-error-message" className="tw-mt-2">
          {error}
        </Alert>
      )}
    </Form>
  );
};

export default InviteForm;
