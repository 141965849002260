import React, { Fragment, useEffect, useState } from 'react';
import { Page, Alert, Link, Button } from 'foundation';
import InternalTenantsResource from 'remote/resources/internal-tenant';
import MFAForm from './mfa/mfa-form';
import { InternalTenant } from 'entities/tenant';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';
import { EditTenantModal } from './edit-tenant-modal';
import { usePermissions } from 'store';
import { Action } from 'types/user';
import { useModalState } from 'utils/use-modal-state';

interface Props {
  tenantId: string;
}

const getErrorMessage = (err: Error, tenant: string): string => {
  if (!(err instanceof ApiClientRequestError)) {
    return `Unexpected error: ${err}`;
  }

  switch (err.reason) {
    case 'tenant-not-found':
    case 'project-not-found':
      return `Tenant "${tenant}" does not exist`;
    default:
      return `Error: ${err.reason || 'Unknown error'}: ${err.responseMessage}`;
  }
};

const TenantPage = ({ tenantId }: Props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const editTenantModal = useModalState();
  const [tenant, setTenant] = useState<InternalTenant | null>(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const permission = usePermissions();
  const canEditTenant = permission.allow(Action.UPDATE, `internal/namespaces/${tenantId}`);

  const fetchData = async () => {
    setLoading(true);
    try {
      if (canEditTenant) {
        const tenantDetails = await InternalTenantsResource.getTenant(tenantId);
        setTenant(tenantDetails);
      }
    } catch (err) {
      setError(getErrorMessage(err, tenantId));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [tenantId]);

  const onEditTenantSuccess = (updatedTenant: InternalTenant) => {
    editTenantModal.closeAndRefreshKey();
    setSuccessMessage('Tenant updated.');
    setTenant(updatedTenant);
  };

  return (
    <Page loading={loading}>
      {canEditTenant ? (
        <>
          {error ? (
            <Alert type="danger" data-testid="user-tenant-error">
              {error}
            </Alert>
          ) : (
            <Fragment>
              <Link href={`#admin/projects/${tenantId}/users`}>Go to tenant users page</Link>
              <h6 data-testid="tenant-settings-heading" className="tw-pt-8 tw-pb-8">
                Settings for tenant: {tenantId}
              </h6>
              <div className="admin-tenant-content-container">
                <MFAForm mfaRequired={tenant ? tenant.mfaRequired : true} tenantId={tenantId} />
                <EditTenantModal
                  open={editTenantModal.visible}
                  tenant={tenant}
                  onSuccess={friendlyName => onEditTenantSuccess(friendlyName)}
                  onClose={editTenantModal.closeAndRefreshKey}
                  key={editTenantModal.key}
                />
                <Button
                  className="admin-tenant-button"
                  onClick={editTenantModal.show}
                  data-testid="tenant-edit-button"
                  type="submit"
                  color="primary"
                >
                  Edit tenant
                </Button>
                {successMessage && (
                  <Alert
                    type="success"
                    data-testid="edit-tenant-success-message"
                    className="tw-mt-2"
                  >
                    {successMessage}
                  </Alert>
                )}
              </div>
            </Fragment>
          )}
        </>
      ) : (
        <h5 data-testid="tenant-settings-access-denied">Access Denied</h5>
      )}
    </Page>
  );
};

export default TenantPage;
