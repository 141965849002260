import React, { SyntheticEvent, useState } from 'react';

import { IconButton, Menu, MenuItem } from 'foundation';
import EditIsolationIdsModal from 'application/edit-isolation-unit';
import { InternalTenant } from 'entities/tenant';

interface ActionsMenuProps {
  tenant: InternalTenant;
  saveIsolationIds: (ids: string[]) => void;
}

const TenantsActionsMenu = ({ tenant, saveIsolationIds }: ActionsMenuProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const handleOpen = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const openEditTenantModal = () => {
    setEditModalOpen(true);
  };

  const closeEditTenantModal = () => {
    setEditModalOpen(false);
  };

  const saveAndClose = (ids: string[]) => {
    closeEditTenantModal();
    handleClose();
    saveIsolationIds(ids);
  };

  return (
    <>
      <div className="tw-grow tw-text-right">
        <IconButton
          className="tw-ml-2 tw-center"
          onClick={handleOpen}
          color="neutral"
          title="Tenant Actions Menu"
          aria-label="Tenant Actions Menu"
          iconName="EllipsisHorizontalIconOutline"
          data-testid={`tenant-action-menu-button-${tenant.id}`}
          clean
        />
      </div>

      <Menu
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        data-testid="tenant-actions-menu"
      >
        <Menu.Items>
          <MenuItem
            title="Edit Isolation IDs"
            name="Edit Isolation IDs"
            onClick={openEditTenantModal}
            data-testid="edit-isolationIds-action-button"
          />
        </Menu.Items>
      </Menu>
      <EditIsolationIdsModal
        tenant={tenant}
        open={editModalOpen}
        onClose={closeEditTenantModal}
        onSuccess={saveAndClose}
      />
    </>
  );
};

export default TenantsActionsMenu;
