import React from 'react';
import { FormInput, FormSelect } from 'foundation/form-element';
import { Checkbox } from '@neo4j-ndl/react';

interface SpdEnabledInputProps extends Omit<React.ComponentProps<typeof Checkbox>, 'onChange'> {
  onChange: (value: boolean) => any;
  disabled?: boolean;
  validationError?: string;
}

interface SpdConfigInputProps extends Omit<React.ComponentProps<typeof FormInput>, 'onChange'> {
  onChange: (value: string) => any;
  disabled?: boolean;
  validationError?: string;
}

export const SpdConfigEnabledInput = ({
  onChange,
  disabled,
  checked,
  validationError,
  ...rest
}: SpdEnabledInputProps) => {
  return (
    <Checkbox
      fluid
      disabled={disabled}
      label="Enable SPD"
      checked={checked}
      onChange={e => onChange(e.target.checked)}
      data-testid="editable-title"
      {...rest}
    />
  );
};

export const SpdConfigInput = ({
  label,
  onChange,
  disabled,
  value,
  validationError,
  ...rest
}: SpdConfigInputProps) => {
  return (
    <FormInput
      fluid
      disabled={disabled}
      label={label}
      value={value}
      onChange={e => onChange(e.target.value)}
      errorText={validationError}
      data-testid="editable-title"
      {...rest}
    />
  );
};

export const SpdSeedTypeInput = ({ onChange, disabled, defaultValue, value, validationError }) => {
  const options = [
    { key: '', value: '', label: 'None' },
    { key: 'base', value: 'base', label: 'Base Uri' },
    { key: 'presigned', value: 'presigned', label: 'Pre-Signed Uris' },
  ];

  return (
    <FormSelect
      label="Seeding Type"
      options={options}
      value={value}
      onChange={onChange}
      disabled={disabled}
      errorText={validationError}
      data-testid="editable-title"
      defaultValue={defaultValue || ''}
    />
  );
};
