import React, { SyntheticEvent, useState } from 'react';
import { Database, isResizable } from 'entities/database';
import ResizeDatabaseModal from './modal';

interface Props {
  database: Database;
}

interface ModalProps {
  open: boolean;
  database: Database;
  _onClose: (c?: () => void) => () => void;
  onClose?: () => void;
}

const ResizeModal = ({ open, database, _onClose, onClose }: ModalProps) => {
  if (!open) return null;

  return <ResizeDatabaseModal open onClose={_onClose(onClose)} database={database} />;
};

export const useResizeModal = ({ database }: Props) => {
  const [open, setOpen] = useState(false);

  const handleClose = onClose => () => {
    setOpen(false);

    if (onClose) onClose();
  };

  const handleResizeClick = (e: SyntheticEvent) => {
    e.stopPropagation();

    setOpen(true);
  };

  const disabled = !isResizable(database);

  const modalProps = {
    open,
    database,
    _onClose: handleClose,
  };

  return { onClick: handleResizeClick, disabled, Modal: ResizeModal, modalProps };
};
