import React, { useState } from 'react';
import track, { useTracking } from 'react-tracking';
import { Dialog, Button, Alert } from 'foundation';
import { InviteDetails, InviteeTenantDetails } from 'types/invite';
import Actions from 'actions';
import { useSession } from 'store';
import { Tier } from 'entities/database';
import { PlanType, Tenant, tierDisplayName } from 'entities/tenant';
import { InviteConsentItem } from './invite-consent-item';
import { SessionStore } from 'state';
import { errorToFriendly } from './error-messages';

interface InviteConsentModalProps {
  open: boolean;
  onClose: () => void;
}

// Just the subset of the session state that we're interested in here
interface InviteSessionState {
  hideInviteConsentForm: boolean;
  planType: PlanType;
  invites: InviteDetails[];
}

export const shouldShowInvitesConsentForm = (state: InviteSessionState): boolean => {
  return !state.hideInviteConsentForm && invitesToShow(state).length > 0;
};

export const invitesToShow = (state: InviteSessionState): InviteDetails[] => {
  const invites = state.invites ?? [];
  const hasEnterpriseInvites = invites.some(inv => inv.PlanType === PlanType.ENTERPRISE);
  if (state.planType === PlanType.ENTERPRISE || hasEnterpriseInvites) {
    // Only show enterprise invites to enterprise users.

    // Or if there are any enterprise invites, only show them.
    // We don't want the user accepting enterprise and self-serve invites
    // at the same time. If they really want to accept a self-serve invite,
    // then they'll need to decline the enterprise invites first.
    return invites.filter(inv => inv.PlanType === PlanType.ENTERPRISE);
  }

  return invites;
};

export const inviteModalWarningText = (
  currentTenant: Tenant,
  currentPlanType: PlanType,
  invitesTo: Set<PlanType>,
  inviteeTenantsWithAffectedInstances: InviteeTenantDetails[]
) => {
  const dualInvites = invitesTo.has(PlanType.SELF_SERVE) && invitesTo.has(PlanType.ENTERPRISE);

  const isUpgrade = currentPlanType === PlanType.SELF_SERVE && invitesTo.has(PlanType.ENTERPRISE);

  const hasTenantsWithAffectedInstances = inviteeTenantsWithAffectedInstances.length > 0;

  const isDowngrade = currentPlanType === PlanType.ENTERPRISE && invitesTo.has(PlanType.SELF_SERVE);

  const isSameLevel = !isUpgrade && !isDowngrade;

  // We're going to lookup the display name for the Enterprise tier, because
  // chances are we might need to use it a few times down below.

  // We lookup the display name from the user's current tenant rather than
  // the one they're being invited to. It doesn't really matter for the
  // purposes of getting a display name as it will work the same regardless.
  const enterpriseDisplayName = tierDisplayName(currentTenant, Tier.ENTERPRISE);
  // Should the enterpriseDisplayName noun be preceded by 'a' or 'an'?
  const enterpriseArticle = getIndefiniteArticle(enterpriseDisplayName);

  if (isSameLevel) {
    // No need for a warning
    return '';
  }
  if (isDowngrade) {
    // We shouldn't even show the modal, so the text should be hidden anyway
    return '';
  }
  if (hasTenantsWithAffectedInstances) {
    const tenantNames = inviteeTenantsWithAffectedInstances.map(
      invite => `'${invite.friendly_name}' [ID: ${invite.tenant_id}]`
    );
    const tenantNamesString =
      tenantNames.length > 1
        ? tenantNames.slice(0, -1).join(', ') + ' and ' + tenantNames.slice(-1)
        : tenantNames[0];
    return (
      `You have one or more active instances in tenant(s) ${tenantNamesString} that you ` +
      `would lose access to when upgrading to ${enterpriseDisplayName}. Before accepting an ` +
      `invite to ${enterpriseArticle} ${enterpriseDisplayName} tenant, please backup and ` +
      'destroy all active instances in these tenants.'
    );
  }
  // Self serve AND enterprise invites
  if (dualInvites) {
    return (
      `Accepting ${enterpriseArticle} ${enterpriseDisplayName} tenant invitation will ` +
      'remove access to any self serve tenants including any current invitations.'
    );
  }
  // This condition should always be true by now, but just to be explicitly clear:
  if (isUpgrade) {
    // Nothing stopping the upgrade, but warn about losing access to old tenants
    return (
      `Note that accepting ${enterpriseArticle} ${enterpriseDisplayName} tenant ` +
      'invitation will remove access to any self serve tenants.'
    );
  }

  // How on earth did we reach here?!
  // Did someone add an enum value, or is there a bug in the logic?
  return '';
};

// For a given noun, return whether it should be preceded by
// 'a' or 'an' as appropriate. E.g. 'a cat' vs 'an apple'.
const getIndefiniteArticle = (noun: string): string => {
  const vowels = ['a', 'e', 'i', 'o', 'u'];
  return vowels.includes(noun[0].toLowerCase()) ? 'an' : 'a';
};

export const InviteConsentModal = track()(({ open, onClose }: InviteConsentModalProps) => {
  const tracking = useTracking();
  const session = useSession();
  const invites = invitesToShow(session);
  const [selectedInvites, setSelectedInvites] = useState<InviteDetails[]>([]);
  const [loading, setLoading] = useState({ reject: false, accept: false });
  const [error, setError] = useState('');

  const handleCheckedChange = (invite: InviteDetails, checked: boolean) => {
    setError('');
    if (checked) {
      setSelectedInvites([...selectedInvites, invite]);
    } else {
      setSelectedInvites(selectedInvites.filter(value => value.InviteId !== invite.InviteId));
    }
  };

  const handleClose = () => {
    tracking.trackEvent({ action: 'dismiss_invite_modal' });
    setError('');
    setSelectedInvites([]);
    onClose();
  };

  const handleReject = async () => {
    setError('');
    setLoading({ reject: true, accept: false });

    try {
      for (let invite of selectedInvites) {
        await Actions.invites.declineInvite(invite.InviteId);
      }

      tracking.trackEvent({
        action: 'decline_invites',
        properties: { declinedInviteCount: selectedInvites.length },
      });
      setLoading({ reject: false, accept: false });
      Actions.invites
        .getInvitesByUser(session.userId)
        .then(result => SessionStore.setInvites(result));
      handleClose();
    } catch (e) {
      setLoading({ ...loading, reject: false });
      setError(errorToFriendly(e));
    }
  };

  const handleConfirm = async () => {
    setError('');
    if (loading.accept || loading.reject) {
      return;
    }
    setLoading({ reject: false, accept: true });

    try {
      for (let invite of selectedInvites) {
        await Actions.invites.acceptInvite(invite.InviteId);
      }

      tracking.trackEvent({
        action: 'accept_invites',
        properties: { acceptedInviteCount: selectedInvites.length },
      });
      setSelectedInvites([]);
      setLoading({ reject: false, accept: false });
      Actions.tenants.clear();
    } catch (e) {
      setLoading({ ...loading, accept: false });
      setError(errorToFriendly(e));
    }
  };

  const affectsAccessToInstances = invite =>
    invite.InviteeTenantsWithAffectedInstances &&
    invite.InviteeTenantsWithAffectedInstances.length > 0;
  const tenantsWithAffectedInstances =
    invites.find(invite => affectsAccessToInstances(invite))?.InviteeTenantsWithAffectedInstances ??
    [];
  const hasTenantsWithAffectedInstances = tenantsWithAffectedInstances.length > 0;

  const actionDisabled = loading.accept || loading.reject || selectedInvites.length === 0;

  const invitesTo = new Set<PlanType>(invites.map(invite => invite.PlanType as PlanType));

  if (!session.tenant) {
    // We clearly don't have enough information to show this modal
    return null;
  }
  const warningText = inviteModalWarningText(
    session.tenant,
    session.planType,
    invitesTo,
    tenantsWithAffectedInstances
  );
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      modalProps={{ 'data-testid': 'invite-consent-modal' }}
    >
      <Dialog.Header>Tenant invitation</Dialog.Header>
      <Dialog.Description>
        <div className="n-body-large">You have been invited to join the following tenant(s):</div>
      </Dialog.Description>
      {!warningText && (
        <Dialog.Subtitle className="tw-mt-2">
          <div className="n-body-medium tw-text-palette-neutral-text-weaker">
            You will still be able to access your current tenant(s).
          </div>
        </Dialog.Subtitle>
      )}
      <Dialog.Content data-testid="invite-consent-modal-content">
        <ul>
          {invites.map(invite => (
            <InviteConsentItem
              invite={invite}
              onCheckedChange={handleCheckedChange}
              checked={selectedInvites.includes(invite)}
              key={invite.InviteId}
            />
          ))}
        </ul>
        {warningText && (
          <Alert
            className="tw-mt-2"
            type="warning"
            description={warningText}
            data-testid="invite-consent-modal-warning-text"
          />
        )}
        {error && <Alert className="tw-mt-2" type="danger" description={error} />}
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          onClick={handleReject}
          loading={loading.reject}
          disabled={actionDisabled}
          data-testid="reject-invites-button"
          color="danger"
          fill="outlined"
        >
          Decline
        </Button>
        <Button
          onClick={handleConfirm}
          loading={loading.accept}
          disabled={actionDisabled || hasTenantsWithAffectedInstances}
          data-testid="accept-invites-button"
        >
          Accept invite(s)
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
});
