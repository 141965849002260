import React, { ChangeEvent, useState } from 'react';
import { Dialog, Button, FormInput, Alert, IconButton } from 'foundation';
import { InternalTenant } from 'entities/tenant';
import Actions from 'actions';

interface EditIsolationIdsModalProps {
  tenant: InternalTenant;
  open: boolean;
  onClose(): void;
  onSuccess(ids: string[]): void;
}

const EditIsolationIdsModal = ({
  tenant,
  open,
  onClose,
  onSuccess,
}: EditIsolationIdsModalProps) => {
  const [isolationIds, setIsolationIds] = useState<string[]>(tenant.isolationIds ?? ['']);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  const onSaveChanges = async () => {
    const trimmedIsolationIds = isolationIds.map(id => id.trim()).filter(id => id.length > 0);
    setLoading(true);
    setError(null);
    try {
      await Actions.namespaces.updateIsolationIds(tenant.id, trimmedIsolationIds);
      setLoading(false);
      setIsolationIds(trimmedIsolationIds);
      onSuccess(trimmedIsolationIds);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const onDiscard = () => {
    setIsolationIds(tenant.isolationIds ?? ['']);
    setError(null);
    onClose();
  };

  const addIsolationIdField = () => setIsolationIds(ids => [...ids, '']);

  const removeIsolationIdField = (index: number) =>
    setIsolationIds(ids => ids.filter((_, i) => i !== index));

  const handleIsolationIdChange = (event: ChangeEvent<HTMLInputElement>, index: number) =>
    setIsolationIds(ids =>
      ids.map((id, i) => {
        if (i === index) {
          return event.target.value;
        } else {
          return id;
        }
      })
    );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      modalProps={{
        'data-testid': 'edit-isolationIds-modal',
      }}
    >
      <Dialog.Header>Edit Isolation IDs</Dialog.Header>
      <Dialog.Description>
        <div className="n-body-large">
          Edit, add or remove isolation IDs for tenant {`"${tenant.internalName}"`}
        </div>
      </Dialog.Description>
      <Dialog.Content data-testid="edit-IsolationIds-modal-content">
        <h6>Isolation IDs</h6>
        {isolationIds.map((value, index) => (
          <FormInput
            key={index}
            className={index > 0 && 'tw-mt-2'}
            fluid
            aria-label="Edit isolation id"
            data-testid={`edit-isolation-id-input-${index + 1}`}
            value={value}
            onChange={event => handleIsolationIdChange(event, index)}
            rightIcon={
              index > 0 && (
                <IconButton
                  iconName="TrashIconOutline"
                  onClick={() => removeIsolationIdField(index)}
                  data-testid={`remove-isolation-id-field-${index + 1}`}
                  title="Remove isolation Unit"
                />
              )
            }
          />
        ))}
        <Button
          color="neutral"
          className="tw-mt-2"
          fill="outlined"
          onClick={addIsolationIdField}
          disabled={isolationIds.length >= 2}
          data-testid="add-isolation-id-textfield"
        >
          Add Isolation ID
        </Button>
        <div className="form-field-hint n-body-medium tw-mt-4">
          Example <code>ni-xxxx-yyy</code> for GCP or a numerical ID for AWS. The ID can be found in
          the GitHub Pull Request with the new isolation unit configuration, as provisioned by the
          platform team.
        </div>
        {error && (
          <Alert
            className="tw-mt-2"
            type="danger"
            data-testid="edit-isolationIds-error-message"
            description={
              'An error occurred while updating the tenant. Please reload the page and try again. ' +
              error.toString()
            }
          />
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          onClick={onDiscard}
          color="neutral"
          fill="outlined"
          disabled={loading}
          data-testid="discard-isolationIds-changes-button"
        >
          Discard
        </Button>
        <Button
          onClick={onSaveChanges}
          loading={loading}
          data-testid="save-isolationIds-changes-button"
        >
          Save Changes
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export default EditIsolationIdsModal;
