import React from 'react';
import { OauthClient } from 'types/oauth';
import { parseISO, format } from 'date-fns';
import {
  DataGrid,
  DataGridComponents,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from 'foundation';

interface Props {
  clients: OauthClient[];
}

const columnHelper = createColumnHelper<OauthClient>();

export const OAuthClientTable = ({ clients }: Props) => {
  const table = useReactTable({
    data: clients,
    columns: [
      columnHelper.accessor('Name', {
        header: () => 'Name',
        minSize: 100,
      }),
      columnHelper.accessor('UserId', {
        header: () => 'User ID',
        minSize: 100,
      }),
      columnHelper.accessor('ClientId', {
        header: () => 'Client ID',
        minSize: 100,
      }),
      columnHelper.accessor('CreatedAt', {
        cell: client => format(parseISO(client.getValue()), 'do MMM H:mm a x'),
        header: () => 'Created at',
        minSize: 100,
        enableResizing: false,
      }),
    ],
    initialState: {
      pagination: {
        pageSize: clients.length,
      },
    },
    enableSorting: false,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <div className="tw-mt-6 tw-w-full">
      <DataGrid
        isResizable={true}
        tableInstance={table}
        styling={{
          headerStyle: 'clean',
        }}
        components={{
          Navigation: null,
          Body: props => (
            <DataGridComponents.Body
              {...props}
              innerProps={{
                style: {
                  borderTop: '1px solid rgb(var(--theme-palette-neutral-border-weak))',
                },
              }}
            />
          ),
        }}
      />
    </div>
  );
};
