import React, { SyntheticEvent, useState } from 'react';
import { IconButton, Menu, MenuItem, Tip } from 'foundation';

import { Database, isPauseVisible, isResumeVisible, Tier } from 'entities/database';
import { isProductAvailable, getAvailableTiers, tierDisplayName } from 'entities/tenant';
import { useRenameModal } from 'application/rename-db';
import { useClearDatabaseModal } from 'application/clear-db';
import { useResizeModal } from 'application/resize-db';
import { useChangeSecondariesModal } from 'application/edit-secondaries';
import { useChangeCDCEnrichmentModeModal } from 'application/edit-cdc-enrichment-mode';
import { CDCEnrichmentMode } from 'components/application/edit-cdc-enrichment-mode/form-data';
import { usePermissions, useSession } from 'store';
import PauseDatabase from 'application/pause-db';
import ResumeDatabase from 'application/resume-db';
import DeleteDatabase from 'application/delete-db';
import { CloneMode, useCloneModal } from 'application/clone-db';
import { Action } from 'types/user';
import { PermissionTip } from 'application/permission-tip';
import {
  CDCPauseDatabaseTooltipMessage,
  CDCResumeDatabaseTooltipMessage,
} from '../edit-cdc-enrichment-mode/cdc-tooltips';
import { isCDCEnrichmentModeEnabled } from 'entities/cdc-enrichment-mode';
import { needsMigration } from 'utils/neo4j-versions';
import { OpenWithMigrationAssistant } from 'application/open-with/open-with-migration-assistant';
import { useExtendTrialModal } from '../extend-trial';

interface DatabaseActionsProps
  extends Omit<React.ComponentProps<typeof IconButton>, 'ref' | 'aria-label' | 'iconName'> {
  children?: string | React.ReactNode;
  database: Database;
}

const DbOptions = ({ database, ...rest }: DatabaseActionsProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const session = useSession();
  const { allow } = usePermissions();

  const allowRename = allow(
    Action.UPDATE,
    `namespaces/${session.currentTenant}/databases/${database.DbId}`
  );
  const allowClear = allow(
    Action.OVERWRITE,
    `namespaces/${session.currentTenant}/databases/${database.DbId}`
  );
  const allowUpdate = allow(
    Action.UPDATE,
    `namespaces/${session.currentTenant}/databases/${database.DbId}`
  );
  const allowCloneToNew = allow(Action.CREATE, `namespaces/${session.currentTenant}/databases`);
  const allowUpgrade = allow(Action.CREATE, `namespaces/${session.currentTenant}/databases`);
  const allowCloneToExisting = allow(
    Action.OVERWRITE,
    `namespaces/${session.currentTenant}/databases/${database.DbId}`
  );
  const shouldDisplayCDCEnrichmentMode = isCDCEnrichmentModeEnabled(session, database);

  const isProTrial = !!database.ProTrialEndTime;

  const availableTiers = getAvailableTiers(session);

  const {
    onClick: renameOnClick,
    disabled: renameDisabled,
    Modal: RenameModal,
    modalProps: renameModalProps,
  } = useRenameModal({
    database,
  });

  const {
    onClick: clearDatabaseOnClick,
    disabled: clearDatabaseDisabled,
    Modal: ClearDatabaseModal,
    modalProps: clearDatabaseModalProps,
  } = useClearDatabaseModal({
    database,
  });

  const {
    onClick: resizeOnClick,
    disabled: resizeDisabled,
    Modal: ResizeModal,
    modalProps: resizeModalProps,
  } = useResizeModal({
    database,
  });

  const {
    onClick: changeSecondariesOnClick,
    disabled: changeSecondariesDisabled,
    Modal: ChangeSecondariesModal,
    modalProps: changeSecondariesModalProps,
  } = useChangeSecondariesModal({
    database,
  });

  const {
    onClick: changeCDCEnrichmentModeOnClick,
    disabled: changeCDCEnrichmentModeDisabled,
    Modal: ChangeCDCEnrichmentModeModal,
    modalProps: changeCDCEnrichmentModeModalProps,
  } = useChangeCDCEnrichmentModeModal({
    database,
  });

  const {
    onClick: cloneClick,
    disabled: cloneDisabled,
    options: cloneOptions,
    Modal: CloneModal,
    modalProps: cloneModalProps,
  } = useCloneModal({ database, session });

  const {
    onClick: extendTrialClick,
    Modal: ExtendTrialModal,
    modalProps: extendTrialModalProps,
  } = useExtendTrialModal({});

  const handleOpen = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);
  const showSecondaries =
    session.tenant.capabilities?.secondaries &&
    database.DesiredSettings.Version === '5' &&
    database.Tier === Tier.ENTERPRISE;

  return (
    <>
      <IconButton
        onClick={handleOpen}
        size="medium"
        color="neutral"
        title="Additonal Database Actions"
        data-testid="db-additional-actions"
        aria-label="Additional database actions"
        iconName="EllipsisHorizontalIconOutline"
        {...rest}
      />
      <Menu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl}>
        <Menu.Items>
          <PermissionTip hasPermission={allowRename}>
            <MenuItem
              onClick={renameOnClick}
              disabled={renameDisabled || !allowRename}
              title="Rename"
              data-testid="rename"
            />
          </PermissionTip>
          {(database.Tier === Tier.FREE || database.Tier === Tier.PROFESSIONAL) && (
            <PermissionTip hasPermission={allowClear}>
              <MenuItem
                onClick={clearDatabaseOnClick}
                disabled={clearDatabaseDisabled || !allowClear}
                title="Reset to blank"
                data-testid="reset-to-blank"
              />
            </PermissionTip>
          )}

          {database.Tier !== Tier.FREE && !isProTrial && (
            <PermissionTip hasPermission={allowUpdate}>
              <MenuItem
                onClick={resizeOnClick}
                disabled={resizeDisabled || !allowUpdate}
                title="Resize"
                data-testid="resize"
              />
            </PermissionTip>
          )}
          {showSecondaries && (
            <PermissionTip hasPermission={allowUpdate}>
              <MenuItem
                onClick={changeSecondariesOnClick}
                disabled={changeSecondariesDisabled || !allowUpdate}
                title="Edit Secondary Count"
                data-testid="edit-secondaries"
              />
            </PermissionTip>
          )}
          {shouldDisplayCDCEnrichmentMode && (
            <PermissionTip hasPermission={allowUpdate}>
              <MenuItem
                onClick={changeCDCEnrichmentModeOnClick}
                disabled={changeCDCEnrichmentModeDisabled || !allowUpdate}
                title="Edit CDC mode"
                data-testid="edit-cdc-enrichment-mode"
              />
            </PermissionTip>
          )}
          {database.Tier === Tier.FREE && (
            <PermissionTip hasPermission={allowUpgrade}>
              <MenuItem
                onClick={() => cloneClick(Tier.PROFESSIONAL, CloneMode.UPGRADE)}
                disabled={cloneDisabled || !allowUpgrade}
                title={`Upgrade to ${tierDisplayName(session.tenant, Tier.PROFESSIONAL)}`}
                data-testid="upgrade-db"
              />
            </PermissionTip>
          )}
          {database.Tier === Tier.PROFESSIONAL && availableTiers.has(Tier.MTE) && !isProTrial && (
            <PermissionTip hasPermission={allowUpgrade}>
              <MenuItem
                onClick={() => cloneClick(Tier.MTE, CloneMode.UPGRADE)}
                disabled={cloneDisabled || !allowUpgrade}
                title={`Upgrade to ${tierDisplayName(session.tenant, Tier.MTE)}`}
                data-testid="upgrade-db"
              />
            </PermissionTip>
          )}
          {!isProTrial && (
            <>
              <PermissionTip hasPermission={allowCloneToNew}>
                <MenuItem
                  title="Clone To New"
                  disabled={cloneDisabled || !allowCloneToNew}
                  data-testid="clone-to-new"
                >
                  {({ nestedMenuProps }) => (
                    <Menu
                      {...nestedMenuProps}
                      minWidth={105}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    >
                      <Menu.Items>
                        {cloneOptions.map(option => (
                          <MenuItem
                            key={option.product}
                            title={tierDisplayName(session.tenant, option.tier)}
                            onClick={() => cloneClick(option.tier, CloneMode.NEW)}
                            data-testid={`clone-to-new-${option.tier}`}
                            disabled={!isProductAvailable(session.providerConfigs, option.product)}
                          />
                        ))}
                      </Menu.Items>
                    </Menu>
                  )}
                </MenuItem>
              </PermissionTip>
              <PermissionTip hasPermission={allowCloneToExisting}>
                <MenuItem
                  title="Clone To Existing"
                  disabled={cloneDisabled || !allowCloneToExisting}
                  data-testid="clone-to-existing"
                >
                  {({ nestedMenuProps }) => (
                    <Menu
                      {...nestedMenuProps}
                      minWidth={105}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    >
                      <Menu.Items>
                        {cloneOptions.map(option => (
                          <MenuItem
                            key={option.product}
                            title={tierDisplayName(session.tenant, option.tier)}
                            onClick={() => cloneClick(option.tier, CloneMode.EXISTING)}
                            data-testid={`clone-to-existing-${option.tier}`}
                            disabled={!isProductAvailable(session.providerConfigs, option.product)}
                          />
                        ))}
                      </Menu.Items>
                    </Menu>
                  )}
                </MenuItem>
              </PermissionTip>
            </>
          )}
          {session.tenant.availableActions.extend_pro_trial.enabled && isProTrial && (
            <MenuItem
              title="Extend trial"
              data-testid="extend-pro-trial"
              onClick={() => extendTrialClick()}
            />
          )}
          {needsMigration(database.DesiredSettings.Version) && (
            <OpenWithMigrationAssistant database={database}>
              {({ onClick, disabled }) => (
                <MenuItem
                  title="Migration Readiness"
                  data-testid="migration-readiness"
                  disabled={disabled}
                  onClick={e => {
                    handleClose();
                    return onClick(e);
                  }}
                />
              )}
            </OpenWithMigrationAssistant>
          )}

          {/* 
              Leaving here for a future card to enable it and handle
              routing to the snapshots page after clicking the button 
          */}
          {/* <TakeSnapshot database={database}>
            {({ onClick, disabled, loading }) => (
              <MenuItem
                onClick={onClick}
                disabled={disabled || loading}
                title="Take Snapshot"
                {...(loading && { icon: <LoadingSpinner size="small" /> })}
              />
            )}
          </TakeSnapshot> */}
        </Menu.Items>
      </Menu>
      {allowRename && <RenameModal {...renameModalProps} onClose={handleClose} />}
      {allowClear && <ClearDatabaseModal {...clearDatabaseModalProps} onClose={handleClose} />}
      {allowUpdate && <ResizeModal {...resizeModalProps} onClose={handleClose} />}
      {allowUpdate && (
        <ChangeSecondariesModal {...changeSecondariesModalProps} onClose={handleClose} />
      )}
      {allowUpdate && shouldDisplayCDCEnrichmentMode && (
        <ChangeCDCEnrichmentModeModal
          {...changeCDCEnrichmentModeModalProps}
          onClose={handleClose}
        />
      )}
      {(allowCloneToNew || allowCloneToExisting || allowUpgrade) && (
        <CloneModal {...cloneModalProps} onClose={handleClose} />
      )}
      <ExtendTrialModal {...extendTrialModalProps} />
    </>
  );
};

export const DatabaseActions = ({ database, ...rest }: DatabaseActionsProps) => {
  const session = useSession();
  const suspended = session.tenant.suspended;
  const hasCDCEnrichmentMode =
    database.DesiredSettings.CDCEnrichmentMode &&
    database.DesiredSettings.CDCEnrichmentMode !== CDCEnrichmentMode.OFF;

  const showResume = isResumeVisible(database);
  // Don't show pause if we are showing resume
  const showPause = isPauseVisible(database) && !showResume;

  return (
    <>
      {showPause && (
        <PauseDatabase database={database}>
          {({ onClick, disabled, loading, allowPause }) => (
            <Tip
              isDisabled={
                allowPause && !hasCDCEnrichmentMode && database.AvailableActions.pause.enabled
              }
            >
              <Tip.Trigger>
                <IconButton
                  className="tw-h-6 tw-w-6"
                  onClick={onClick}
                  disabled={disabled}
                  loading={loading}
                  size="medium"
                  data-testid="pause"
                  title="Pause Database"
                  iconName="PauseIconOutline"
                  aria-label="Pause instance"
                  {...rest}
                />
              </Tip.Trigger>
              <Tip.Content style={{ width: 200 }} className="tw-bg-palette-neutral">
                <Tip.Body>
                  {hasCDCEnrichmentMode && allowPause && database.AvailableActions.pause.enabled
                    ? CDCPauseDatabaseTooltipMessage
                    : !allowPause
                    ? 'You don&apos;t have permission to perform this action.'
                    : database.AvailableActions.pause.message}
                </Tip.Body>
              </Tip.Content>
            </Tip>
          )}
        </PauseDatabase>
      )}
      {showResume && (
        <ResumeDatabase database={database} suspended={suspended}>
          {({ onClick, disabled, loading, allowResume }) => (
            <Tip
              isDisabled={
                allowResume && !hasCDCEnrichmentMode && database.AvailableActions.resume.enabled
              }
            >
              <Tip.Trigger>
                <IconButton
                  className="tw-h-6 tw-w-6"
                  onClick={onClick}
                  disabled={disabled}
                  loading={loading}
                  size="medium"
                  data-testid="resume"
                  title="Resume Database"
                  iconName="PlayIconOutline"
                  aria-label="Resume instance"
                  {...rest}
                />
              </Tip.Trigger>
              <Tip.Content style={{ width: 200 }} className="tw-bg-palette-neutral">
                <Tip.Body>
                  {hasCDCEnrichmentMode && allowResume && database.AvailableActions.resume.enabled
                    ? CDCResumeDatabaseTooltipMessage
                    : !allowResume
                    ? 'You don&apos;t have permission to perform this action.'
                    : database.AvailableActions.resume.message}
                </Tip.Body>
              </Tip.Content>
            </Tip>
          )}
        </ResumeDatabase>
      )}
      <DeleteDatabase database={database}>
        {({ onClick, loading, disabled, allowDelete }) => (
          <PermissionTip hasPermission={allowDelete}>
            <IconButton
              className="tw-h-6 tw-w-6"
              onClick={onClick}
              disabled={disabled}
              loading={loading}
              size="medium"
              title="Delete Database"
              data-testid="destroy"
              iconName="TrashIconOutline"
              aria-label="Delete instance"
              danger
              {...rest}
            />
          </PermissionTip>
        )}
      </DeleteDatabase>
      <DbOptions database={database} {...rest} />
    </>
  );
};
