import React, { useMemo } from 'react';
import { createColumnHelper, StickyActionsDataGrid, useDefaultTable } from 'components/foundation';
import { Organization } from 'types/organization';
import { BillingAccount } from 'types/billing-account';

const OrganizationBillingAccountsGuard = (
  component: React.ComponentType<{
    organization: Organization;
  }>
) => ({ organization }: { organization: Organization }) => {
  const Component = component;
  return <Component organization={organization} />;
};

const billingAccountsHeaderHelper = createColumnHelper<BillingAccount>();

export const OrganizationBillingAccountsBase = ({
  organization,
}: {
  organization: Organization;
}) => {
  const columns = useMemo(
    () => [
      billingAccountsHeaderHelper.accessor('id', {
        header: 'Account ID',
        minSize: 100,
      }),
      billingAccountsHeaderHelper.accessor('billingModel', {
        header: 'Billing Model',
        minSize: 100,
      }),
      billingAccountsHeaderHelper.accessor('billingProcessor', {
        header: 'Billing Processor',
        minSize: 100,
      }),
      billingAccountsHeaderHelper.accessor('paymentMethod', {
        header: 'Payment Method',
        minSize: 100,
      }),
      billingAccountsHeaderHelper.accessor('pricingPlan', {
        header: 'Pricing Plan',
        minSize: 100,
      }),
      billingAccountsHeaderHelper.accessor('projectIds', {
        header: 'Project IDs',
        minSize: 100,
      }),
      billingAccountsHeaderHelper.accessor('status', {
        header: 'Status',
        minSize: 100,
      }),
    ],
    []
  );

  const table = useDefaultTable({
    columns: columns,
    data: organization.billingAccounts,
  });
  return (
    <div>
      <h5>Billing Accounts</h5>
      <StickyActionsDataGrid tableInstance={table} />
    </div>
  );
};

export const OrganizationBillingAccounts = OrganizationBillingAccountsGuard(
  OrganizationBillingAccountsBase
);
