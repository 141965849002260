import LoadError from 'components/application/load-error';
import { Alert, Button, LoadingSpinner, Page, Tip } from 'components/foundation';
import { Tenant } from 'entities/tenant';
import React, { useState, useEffect } from 'react';
import { useCmeks } from 'remote/resources/encryption-keys';
import { usePermissions, useSession } from 'store';
import { Action } from 'types/user';
import { CmekDialog } from './instructions';
import { CmekTable } from './table';
import { useTracking } from 'react-tracking';
import { EncryptionKey } from 'types/encryption-keys';

const CmekPage = () => {
  const { tenant } = useSession();
  const [instructionsOpen, setInstructionsOpen] = useState(false);
  const [encryptionKey, setEncryptionKey] = useState<EncryptionKey>();
  const permissions = usePermissions();
  const tracking = useTracking();

  const handleOpenDialog = () => {
    tracking.trackEvent({
      action: 'open_create_customer_managed_key',
      properties: { event_label: 'clicked' },
    });
    setInstructionsOpen(true);
  };
  const handleCloseDialog = () => setInstructionsOpen(false);

  const canCreateCmek = permissions.allow(Action.CREATE, `namespaces/${tenant.id}/encryption-keys`);

  const renderCmekContent = () => {
    if (!tenant.capabilities.cmek) {
      return <FeatureDisabled tenant={tenant} />;
    }

    const canReadCmeks = permissions.allow(
      Action.READ,
      `namespaces/${tenant.id}/encryption-keys/*`
    );

    if (!canReadCmeks) {
      return <PermissionDenied tenant={tenant} />;
    }

    return (
      <CmekContent
        tenant={tenant}
        instructionsOpen={instructionsOpen}
        encryptionKey={encryptionKey}
        handleCloseDialog={handleCloseDialog}
        updateEncryptionKey={setEncryptionKey}
      />
    );
  };

  return (
    <Page plain fullWidth>
      <div className="tw-flex tw-justify-between">
        <h4 className="tw-mb-6">Customer Managed Keys</h4>
        {
          <div className="tw-flex tw-gap-4">
            <Tip>
              <Tip.Trigger>
                <Button
                  onClick={handleOpenDialog}
                  disabled={!canCreateCmek}
                  data-testid="new-encryption-key-btn"
                >
                  Add
                </Button>
              </Tip.Trigger>
              {!canCreateCmek && (
                <Tip.Content className="tw-max-w-sm" isPortaled={false}>
                  <p>
                    You need to be an admin of the <strong>{tenant.name}</strong> tenant to add a
                    Customer Managed Key.
                  </p>
                </Tip.Content>
              )}
            </Tip>
          </div>
        }
      </div>
      {renderCmekContent()}
    </Page>
  );
};

interface PermissionDeniedProps
  extends Omit<
    React.ComponentProps<typeof Alert>,
    'type' | 'closeable' | 'onClose' | 'title' | 'description'
  > {
  title?: React.ComponentProps<typeof Alert>['title'];
  tenant: Tenant;
}

const PermissionDenied = ({
  title = 'Permission denied',
  tenant,
  ...rest
}: PermissionDeniedProps) => {
  return (
    <Alert
      type="warning"
      title={title}
      data-testid="cmek-permission-denied-alert"
      description={
        <div>
          Customer Managed Keys
          <p>
            You must be an admin of the <strong>{tenant.name}</strong> tenant to read encryption key
            configurations.
          </p>
        </div>
      }
      {...rest}
    />
  );
};

/**
 * This component mostly exists to make sure we only trigger
 * the cmek query after we have loaded in our tenant
 */
const CmekContent = ({
  tenant,
  instructionsOpen,
  encryptionKey,
  handleCloseDialog,
  updateEncryptionKey,
}: {
  tenant: Tenant;
  instructionsOpen: boolean;
  encryptionKey: EncryptionKey;
  handleCloseDialog: () => void;
  updateEncryptionKey: (encryptionKey: EncryptionKey) => void;
}) => {
  const [step, setStep] = useState(0);
  const cmeksQuery = useCmeks(tenant);
  const tracking = useTracking();

  const handleClose = () => {
    handleCloseDialog();
    setStep(0);
  };

  useEffect(() => {
    if (instructionsOpen) {
      setStep(0);
    }
  }, [instructionsOpen]);

  const handleCreateSuccess = (encryptionKey: EncryptionKey) => {
    cmeksQuery.refetch();
    tracking.trackEvent({
      action: 'create_customer_managed_key',
      properties: { tier: encryptionKey.tier },
    });
  };

  if (cmeksQuery.isLoading) {
    return <LoadingSpinner size="large" expand />;
  }

  if (cmeksQuery.isLoadingError) {
    return <LoadError />;
  }

  const cmeks = cmeksQuery.data;

  return (
    <>
      <CmekTable
        tenant={tenant}
        cmeks={cmeks}
        updateStep={setStep}
        updateEncryptionKey={updateEncryptionKey}
        handleClose={handleClose}
      />
      {instructionsOpen && (
        <CmekDialog
          tenant={tenant}
          step={step}
          encryptionKey={encryptionKey}
          updateStep={setStep}
          updateEncryptionKey={updateEncryptionKey}
          onClose={handleClose}
          onSuccess={encryptionKey => handleCreateSuccess(encryptionKey)}
        />
      )}
    </>
  );
};

const FeatureDisabled = ({ tenant }) => {
  return (
    <Alert
      type="info"
      title="Feature disabled"
      data-testid="cmek-feature-disabled-alert"
      description={
        <div>
          <p>
            The <strong>Customer Manged Keys</strong> feature is not enabled for tenant{' '}
            {tenant.name}.
          </p>
        </div>
      }
    />
  );
};

export default CmekPage;
